<template>
  <atom-link
    v-if="link"
    :to="link"
    class="wrapper btn d-inline-flex align-items-center text-decoration-none"
    :new-window="newWindow"
    :class="variants"
  >
    <slot />
    <span v-if="arrow" class="arrow-wrapper">
      <icon-arrow class="arrow" />
    </span>
  </atom-link>
  <button
    v-else
    class="wrapper btn d-inline-flex align-items-center"
    :class="variants"
    :disabled="disabled"
    :type="type"
    @click="emits(Emits.CLICK, $event)"
  >
    <slot />
    <span v-if="arrow" class="arrow-wrapper">
      <icon-arrow class="arrow" />
    </span>
  </button>
</template>

<script lang="ts" setup>
import IconArrow from '@web/packeta-ui-styleguide/icons/other/icon-arrow.svg'
import { ref, watch } from 'vue'
import { Emits } from '~common/enums'
import type { AtomButtonEmits, AtomButtonProps } from '~common/types'
import AtomLink from '~components/atoms/AtomLink.vue'

const props = defineProps<AtomButtonProps>()
const emits = defineEmits<AtomButtonEmits>()

const getVariants = () => {
  return {
    'btn-primary':
      !props.outline && !props.light && !props.outlineGrey && !props.navbar && !props.disabled,
    'btn-disabled': props.disabled && !props.outlineGrey,
    'btn-outline-grey': props.outlineGrey && !props.disabled,
    'btn-navbar': props.navbar && !props.disabled,
    'btn-outline-grey-disabled': props.outlineGrey && props.disabled,
    'btn-outline-primary': props.outline && !props.light,
    'btn-outline-light': props.outline && props.light,
    'btn-sm': props.small,
    'btn-lg': props.large,
    'is-right': props.right,
    'is-left': props.left,
    'background-grey': props.backgroundGrey,
  }
}
const variants = ref(getVariants())
watch(
  () => props.disabled,
  () => {
    variants.value = getVariants()
  },
  { immediate: true },
)
</script>

<style lang="scss" scoped>
.wrapper {
  &:not(.is-right):not(.is-left):not(.btn-outline-light):not(.btn-outline-grey):not(
      .btn-navbar
    ):not(.btn-outline-primary):not(.btn-disabled):not(.btn-outline-grey-disabled) {
    @include addCorners('small', 'both', $base-red, 'nogradient', 'bg-grey', $red-500-hover);
  }

  &:not(.is-right):not(.is-left):not(.btn-outline-light):not(.btn-outline-grey):not(
      .btn-navbar
    ):not(.background-grey):not(.btn-outline-primary):not(.btn-disabled):not(
      .btn-outline-grey-disabled
    ) {
    @include addCorners('small', 'both', $base-red, 'nogradient', 'bg-white', $red-500-hover);
  }

  &.btn-outline-light {
    @include addCorners('small', 'both', $base-white, 'nogradient', 'bg-grey');
  }

  &.btn-outline-light:not(.background-grey) {
    @include addCorners('small', 'both', $base-white, 'nogradient', 'bg-main-red');

    @include focus-visible('solid', $base-white);
  }

  &.is-left {
    @include addCorners('small', 'left', $base-red, 'nogradient', 'bg-grey', $red-500-hover);
  }

  &.is-left:not(.background-grey) {
    @include addCorners('small', 'left', $base-red, 'nogradient', 'bg-white', $red-500-hover);
  }

  &.is-right {
    @include addCorners('small', 'right', $base-red, 'nogradient', 'bg-grey', $red-500-hover);
  }

  &.is-right:not(.background-grey) {
    @include addCorners('small', 'right', $base-red, 'nogradient', 'bg-white', $red-500-hover);
  }

  @include focus-visible;

  &.btn-outline-grey {
    border-color: $grey-200-disable;
    color: $base-grey;

    @include addCorners(
      'small',
      'both',
      $grey-200-disable,
      'nogradient',
      'bg-grey',
      $red-500-hover
    );

    &:hover {
      border-color: $red-500-hover;
      color: $red-500-hover;
    }
  }

  &.btn-outline-grey:not(.background-grey) {
    border-color: $grey-200-disable;
    color: $base-grey;

    @include addCorners(
      'small',
      'both',
      $grey-200-disable,
      'nogradient',
      'bg-white',
      $red-500-hover
    );

    &:hover {
      border-color: $red-500-hover;
      color: $red-500-hover;
    }
  }

  &.btn-navbar {
    border: none;
    color: $base-grey;

    &:hover {
      color: $base-red;
    }
  }

  &.btn-navbar.active {
    color: $base-red;
  }

  &.btn-outline-primary {
    @include addCorners('small', 'both', $base-red, $hoverColor: $red-300);

    &:hover {
      border-color: $red-300;
      background-color: $red-300;
    }
  }

  &.btn.btn-disabled {
    @include addCorners('small', 'both', $red-100-disable);

    background-color: $red-100-disable;
    border-color: $red-100-disable;
    color: $base-white;
  }

  &.btn-outline-grey-disabled {
    @include addCorners('small', 'both', $grey-200-disable);

    border-color: $grey-200-disable;
    color: $grey-200-disable;
  }

  &.btn-lg {
    height: 50px;
  }
}

.btn-primary:hover {
  background-color: $red-500-hover;
  border-color: $red-500-hover;
}

.btn-primary:focus {
  background-color: $red-300;
  border-color: $red-300;
}

.btn-outline-light:hover {
  color: $base-red;
}

.arrow-wrapper {
  display: flex;
  align-items: center;
  padding-left: 0.8em;
  position: relative;
  top: 1px;
}

.arrow {
  height: 0.8em;
  rotate: 270deg;
}
</style>
