<template>
  <div v-if="useOnlyMobileVersion ? viewport.isLessThan('lg') : true">
    <div @click="open">
      <slot name="activator" />
    </div>

    <teleport to="#teleports">
      <div
        v-if="viewport.isLessThan('lg') && opened"
        ref="modal"
        class="modal"
        :class="{ visible: opened }"
        tabindex="-1"
      >
        <div
          class="modal-top pt-24 px-24 px-sm-48 px-lg-24 d-flex justify-content-end align-items-center"
        >
          <atom-button navbar aria-label="Close" class="p-4 text-base-black" @click="close">
            <icon-close viewBox="0 0 24 24" width="24" height="24" />
          </atom-button>
        </div>

        <div v-if="slots.content" class="modal-content" :class="{ 'mb-24': !slots.footer }">
          <slot name="content" :close="close" />
        </div>

        <div v-if="slots.footer" class="modal-footer px-24 px-sm-48 pb-32">
          <slot name="footer" :close="close" />
        </div>
      </div>

      <div v-else-if="opened" class="modal-y-axis">
        <div class="modal">
          <div class="modal-top d-flex justify-content-end align-items-center">
            <atom-button navbar aria-label="Close" class="p-4 text-base-black" @click="close">
              <icon-close viewBox="0 0 24 24" width="24" height="24" />
            </atom-button>
          </div>

          <div
            v-if="slots.content"
            class="modal-content"
            :class="{ 'with-footer': !!slots.footer }"
          >
            <slot name="content" :close="close" />
          </div>

          <div v-if="slots.footer" class="modal-footer bg-base-white">
            <slot name="footer" :close="close" />
          </div>
        </div>
      </div>

      <div v-if="opened" class="modal-overflow" @click="close" />
    </teleport>
  </div>
</template>

<script lang="ts" setup>
import IconClose from '@web/packeta-ui-styleguide/icons/map/icon-close.svg'
import { nextTick, onBeforeUnmount, ref, useSlots, watch } from 'vue'
import { Emits } from '~common/enums'
import type { MoleculeModalEmits, MoleculeModalProps } from '~common/types'
import AtomButton from '~components/atoms/AtomButton.vue'

const viewport = useViewport()
const slots = useSlots()

const props = defineProps<MoleculeModalProps>()
const emits = defineEmits<MoleculeModalEmits>()

const modal = ref()
const opened = ref(false)

watch(
  () => props.defaultOpen,
  (val) => {
    if (val) {
      open()
    } else {
      close()
    }
  },
)

const handleKeydown = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    close()
  }
}

const open = () => {
  opened.value = true

  nextTick(() => {
    modal.value?.focus()
    window.addEventListener('keydown', handleKeydown)
  })
}

const close = () => {
  opened.value = false
  window.removeEventListener('keydown', handleKeydown)

  emits(Emits.CLOSE)
}

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown)
})
</script>

<style lang="scss" scoped>
$modal-top-height: 53px;
$modal-footer-height: 103px;

.modal-overflow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: 11;
  background-color: $base-black;
  opacity: 0.5;
}

.modal {
  position: fixed;
  left: 0;
  background-color: $base-white;
}

.modal.visible {
  animation: 0.3s modal-visible;

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
}

.modal:focus,
.modal:focus-visible {
  outline: none;
}

.modal-footer {
  padding-top: 20px;
  border-top: 1px solid $grey-100-borders;
}

@keyframes modal-visible {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: $media-lg) {
  .modal {
    position: fixed;
    z-index: 12;
    bottom: 0;
    width: 100vw;
    border-radius: $space-24 $space-24 0 0;
  }
}

@media (min-width: $media-lg) {
  .modal-y-axis {
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    width: 1px;
    z-index: 12;
    margin-left: calc((100vw / 2) - 0.5px);
    display: flex;
    align-items: center;
  }

  .modal {
    width: 724px;
    margin-left: calc((100vw - 724px) / 2);

    @include cutCorners('large');
  }

  .modal-top {
    padding: $space-24 40px 0;
    height: $modal-top-height;
  }

  .modal-content {
    padding: 0 40px 5px;
    max-height: calc(100vh - $modal-top-height);
  }

  .modal-content.with-footer {
    max-height: calc(100vh - $modal-top-height - $modal-footer-height);
  }

  .modal-footer {
    padding: 20px 40px $space-32;
    height: 103px;
  }
}

@media (min-width: $media-lg) and (max-height: 600px) {
  .modal-content {
    overflow-y: auto;
  }
}
</style>
