<template>
  <nuxt-link
    v-if="nuxtLink"
    :to="to ? localize(to) : null"
    :exact-active-class="exactActiveClass"
    :target="newWindow ? '_blank' : null"
    :rel="newWindow ? 'noopener' : null"
  >
    <slot />
  </nuxt-link>
  <a
    v-else
    :href="to as string"
    :target="newWindow ? '_blank' : undefined"
    :rel="newWindow ? 'noopener' : undefined"
    :aria-label="ariaLabel"
  >
    <slot />
  </a>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { AtomLinkProps } from '~common/types'

const props = defineProps<AtomLinkProps>()

const localePath = useLocalePath()

const nuxtLink = computed(() => {
  return (
    props.to &&
    !(
      typeof props.to === 'string' &&
      (props.to.startsWith('http') ||
        props.to.startsWith('mailto') ||
        props.to.startsWith('tel') ||
        props.to.startsWith('packeta-app') ||
        props.to.includes('#chatbot'))
    )
  )
})

const localize = (to: string | object) => {
  return typeof to === 'string' && to.startsWith('#')
    ? to
    : localePath(to, props.forceLanguage || undefined)
}
</script>
